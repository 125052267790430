import React, { useState, useEffect } from "react";
import "./styles.css";
import { useParams } from "react-router-dom";
import { URL } from "../components/URL";
const AdminEntryList = () => {
  const { tourneyId } = useParams();
  const [entryList, setEntryList] = useState([]);
  const [selectedShooters, setSelectedShooters] = useState([]);
  useEffect(() => {
    callEntryListAPI();
  }, []);
  const handleCheckboxChange = (shooterId) => {
    setSelectedShooters((prevSelected) =>
      prevSelected.includes(shooterId)
        ? prevSelected.filter((id) => id !== shooterId)
        : [...prevSelected, { shooterId: shooterId }]
    );
  };

  const callEntryListAPI = async () => {
    // add if statements with else at the end
    try {
      let formData = new FormData();
      formData.append("tourneyID", tourneyId);
      console.log(formData);

      const response = await fetch(URL + "entryListAPI.php", {
        method: "POST",
        body: formData,
        credentials: "include",
      });

      console.log("Response Status:", response.status);

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.text();
      console.log("Server Response: ", response.status, data);
      const data2 = JSON.parse(data);

      if (data2.success) {
        console.log("successful");
        console.log("LOOK at this: ", data2);
        setEntryList(data2.entryList);
        console.log("entry list info: ", data2.entryList);
      } else {
        console.error("Form submission failed");
        console.log("data2: ", data2);
      }
    } catch (error) {
      console.error("Error: ", error.message);
    }
  };
  const handleDelete = async () => {
    // add if statements with else at the end
    try {
      let formData = new FormData();
      formData.append("tourneyID", tourneyId);
      for (let [index] of selectedShooters.entries()) {
        // look at api for this
        formData.append("info[]", JSON.stringify(selectedShooters[index]));
      }
      console.log(formData);

      const response = await fetch(URL + "deleteRegistrationAPI.php", {
        method: "POST",
        body: formData,
        credentials: "include",
      });

      console.log("Response Status:", response.status);
      console.log("Selected Shooter IDs:", selectedShooters);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.text();
      console.log("Server Response: ", response.status, data);
      const data2 = JSON.parse(data);
      if (data2.success) {
        alert("Archer successfully deleted.");
        console.log("successful");
        console.log("LOOK at this: ", data2);
        setSelectedShooters([]);
        console.log("entry list info: ", data2.entryList);
      } else {
        console.error("Form submission failed");
        console.log("data2: ", data2);
        alert("Form submission failed.");
      }
    } catch (error) {
      console.error("Error: ", error.message);
      alert("Error: ", error.message);
    }
  };

  return (
    <div>
      <div className="content">
        <h1 className="tournaments-heading">Entry List:</h1>
        <p>Select the archers you would like to delete.</p>
        <ul className="tournamentListItem2">
          {entryList.map((classItem) => (
            <li key={classItem.shooterId}>
              <label>
                <input
                  type="checkbox"
                  name="entryListItem"
                  value={classItem.shooterId}
                  onChange={() => handleCheckboxChange(classItem.shooterId)}
                />
                Name: {classItem.name}. Affiliation: {classItem.affiliation}
              </label>
            </li>
          ))}
        </ul>
        <button onClick={handleDelete}>Delete Selected</button>
      </div>
    </div>
  );
};

export default AdminEntryList;
