import React, { useState } from "react";
import "./adminLogout.css";
import { Navigate } from "react-router-dom";
import { URL } from "../components/URL";

const AdminLogout = () => {
  const [isLoggedIn, setIsLoggedIn] = useState();
  const userStatus1 = async (e) => {
    const response = await fetch(URL + "logoutAPI.php?logout=true", {
      method: "GET",
      credentials: "include",
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.text();
    console.log("Server Response: ", response.status, data);
    const data2 = JSON.parse(data);
    if (data2.logged_out === true) {
      console.log("User is logged out.");
      alert("You are logged out");
      setTimeout(() => {
        window.location.replace(URL + "adminLogin");
      }, 1000);
      setIsLoggedIn(false);
    } else {
      console.error("User is not logged out.");
      alert("error. unable to log user out.");
    }
  };

  const handleLogout = () => {
    userStatus1();
  };
  const handleNotLoggedOut = () => {
    setTimeout(() => {
      alert("You did not log out.");
      window.location.replace(URL + "home");
    }, 1000);
  };

  return (
    <>
      <div className="logout-container">
        <label className="logout-label">Are you sure you want to logout?</label>
        <div className="button-container">
          <button className="logout-button" onClick={() => handleLogout(true)}>
            Yes
          </button>
          <button className="logout-button" onClick={handleNotLoggedOut}>
            No
          </button>
        </div>
      </div>
    </>
  );
};

export default AdminLogout;
