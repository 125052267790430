import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { URL } from "../components/URL";
const AddTourneySponsors = () => {
  // helps get the tournament name from URL
  const { tourneyId } = useParams();
  const [tournamentDetails, setTournamentDetails] = useState(null);
  useEffect(() => {
    fetchTournamentDetails();
  }, [tourneyId]);
  const fetchTournamentDetails = async () => {
    console.log("ID:", tourneyId);
    try {
      let formData = new FormData();
      formData.append("tourneyId", tourneyId);
      const response = await fetch(URL + "tournamentInformationAPI.php", {
        method: "POST",
        body: formData,
        credentials: "include",
      });

      console.log("Response Status:", response.status);
      // const responseBody = await response.text();
      // console.log("Response Body:", responseBody);

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.text();
      console.log("Server Response: ", response.status, data);
      const data2 = JSON.parse(data);
      if (data2.success) {
        console.log("Success");
        console.log("DATA 2:", data2);
        console.log("data2", data2.tournamentInformation);
        setTournamentDetails(data2.tournamentInformation);
      } else {
        console.error("Failed");
        console.log("data2: ", data2);
      }
    } catch (error) {
      console.error("Failed: ", error.message);
    }
  };

  return <div className="content"></div>;
};
export default AddTourneySponsors;
