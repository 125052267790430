import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./tournaments.css";
import { URL } from "../components/URL";
const Tournament = () => {
  const [tournaments, setTournaments] = useState([]);

  useEffect(() => {
    const fetchTournaments = async () => {
      try {
        const response = await fetch(URL + "getTourneyAPI.php", {
          method: "POST",
          credentials: "include",
        });
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.text();
        console.log("Server Response: ", response.status, data);
        const data2 = JSON.parse(data);
        if (data2.success) {
          setTournaments(data2.futureTournaments);
        } else {
          console.error("Failed to fetch tournaments:", data2.error);
        }
      } catch (error) {
        console.error("Error fetching tournaments:", error);
      }
    };

    fetchTournaments();
  }, []);
  return (
    <div className="container">
      <div className="content">
        <h1 className="tournaments-heading">Upcoming Tournaments:</h1>
        <ul className="tournamentList">
          {tournaments.map((tournament, index) => (
            <li key={index} className="tournamentListItem">
              <Link to={`/tournamentDetails/${tournament.tourneyId}`}>
                {tournament.name}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Tournament;
