import React, { useState } from "react";
import "./adminLogin.css";
import { Navigate, Link } from "react-router-dom";
import { URL } from "../components/URL";
const AdminLogin = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password.length < 8) {
      alert("Password must be at least 8 characters long");
      return;
    }
    if (username.length < 8) {
      alert("Username must be at least 8 characters long");
      return;
    } else {
      try {
        let formData = new FormData();
        formData.append("username", username);
        formData.append("password", password);
        console.log(formData);
        const response = await fetch(URL + "loginAPI.php", {
          method: "POST",
          body: formData,
          credentials: "include",
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.text();

        console.log("Server Response: ", response.status, data);
        const data2 = JSON.parse(data);
        if (data2.success) {
          console.log("Login successful");
          alert("Login Successful!!");
          setTimeout(() => {
            // URL will need to change
            window.location.replace(URL + "home");
          }, 1000);
        } else {
          console.error("Login failed. Username and/or password is incorrect.");
          alert("Login failed. Username and/or password is incorrect.");
        }
      } catch (error) {
        console.error("Error during login:", error.message);
        alert("Error during login:", error.message);
      }
    }
  };
  return (
    <>
      <div className="topSpaceContainer"></div>
      <form className="loginFormContainer" onSubmit={handleSubmit}>
        {/*       
      <form className="loginFormContainer"> */}
        <label htmlFor="username">username</label>
        <input
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          placeholder="yourusername@gmail.com"
          id="username"
          name="username"
        />
        <label htmlFor="password">password</label>
        <input
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="********"
          id="password"
          name="password"
        />
        <button type="submit">Log In</button>
      </form>
      {/* fix the css for this */}
      {/* <div style={{ textAlign: "center" }}>
        <Link to={`/adminChangePassword`}>
          <p>Do you want to change your password? Click here.</p>
        </Link>
      </div> */}
    </>
  );
};

export default AdminLogin;
