import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Navbar.css";
import { faBars, faTimes, faTypo3 } from "@fortawesome/free-solid-svg-icons";
import MuskingumArcheryLogo from "../images/MuskingumArcheryLogo.jpg";
import archery1 from "../images/archery1.jpg";
import Logo3 from "../images/Logo3.png";
import { URL } from "../components/URL";
function Navbar() {
  const [click, setClick] = useState(false);
  const handleClick = () => {
    setClick(!click);
    userStatus();
  };
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  // useEffect(() => {
  //   userStatus();
  // }, []);
  const userStatus = async (e) => {
    const response = await fetch(URL + "loggedInAPI.php", {
      method: "POST",
      credentials: "include",
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.text();
    console.log("Server Response: ", response.status, data);
    const data2 = JSON.parse(data);
    if (data2.result == true) {
      console.log("User is logged in.");
      setIsLoggedIn(true);
    } else {
      console.error("User is not logged in.");
      setIsLoggedIn(false);
    }
  };
  return (
    <>
      <nav className="navbar">
        <div className="navbar-container">
          {/* Container for the logo with background */}
          <div className="logo-container">
            <div className="menu-icon" onClick={handleClick}>
              <FontAwesomeIcon icon={click ? faTimes : faBars} />
              <div className="menu-text">MENU</div>
            </div>

            <Link to="/home" className="navbar-logo">
              Muskingum University Archery
            </Link>
            <img
              src={archery1}
              alt="Muskingum Archery Logo"
              style={{ width: "40", height: "70px" }}
            />
          </div>
        </div>
      </nav>
      {/* Navigation Links */}

      {click && (
        <aside className="side-navbar">
          <ul className="side-nav-menu">
            <li className="nav-item">
              <Link to="/home" className="nav-links">
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/tournament" className="nav-links">
                Upcoming Tournaments
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/pastTournament" className="nav-links">
                Past Tournaments
              </Link>
            </li>
            <li className="nav-item">
              {isLoggedIn == true && (
                <Link to="/adminLogout" className="nav-links">
                  Logout
                </Link>
              )}
              {isLoggedIn == false && (
                <Link to="/adminLogin" className="nav-links">
                  Admin Login
                </Link>
              )}
            </li>
            {isLoggedIn == true && (
              <li className="nav-item">
                <p className="nav-links2">Admin Links:</p>
              </li>
            )}
            {isLoggedIn == true && (
              <li className="nav-item">
                <Link to="/adminCreateTourney" className="nav-links">
                  Create Tournament
                </Link>
              </li>
            )}
            {isLoggedIn == true && (
              <li className="nav-item">
                <Link to="/adminChangePassword" className="nav-links">
                  Change Password
                </Link>
              </li>
            )}
            {isLoggedIn == true && (
              <li className="nav-item">
                <Link to="/addContent" className="nav-links">
                  Add Content To Pages
                </Link>
              </li>
            )}

            {isLoggedIn == true && (
              <li className="nav-item">
                <Link to="/adminTourneys" className="nav-links">
                  All Tournaments
                </Link>
              </li>
            )}
          </ul>
        </aside>
      )}
    </>
  );
}

export default Navbar;
