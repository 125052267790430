import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { URL } from "../components/URL";
const AdminTournamentDetails = () => {
  // helps get the tournament name from URL
  const { tourneyId } = useParams();
  const [tournamentDetails, setTournamentDetails] = useState(null);
  const [registrationWindow, setRegistrationWindow] = useState(false);
  useEffect(() => {
    fetchTournamentDetails();
  }, [tourneyId]);
  useEffect(() => {
    if (tournamentDetails) {
      checkTime();
    }
  }, [tournamentDetails]);
  const checkTime = () => {
    if (!tournamentDetails) {
      console.log("Tournament details are not available.");
      return;
    }

    const regStartDate = convertUTCToLocalTime(tournamentDetails.regStartDate);
    const regEndDate = convertUTCToLocalTime(tournamentDetails.regEndDate);
    const currentDate = new Date();

    const formattedCurrentDate = formatDate(currentDate);

    console.log("Current Date:", formattedCurrentDate);
    console.log("Reg Start Date:", regStartDate);
    console.log("Reg End Date:", regEndDate);

    if (regStartDate && regEndDate) {
      const startDate = new Date(regStartDate);
      const endDate = new Date(regEndDate);

      if (currentDate >= startDate && currentDate <= endDate) {
        console.log("Registration is open.");
        setRegistrationWindow(true);
      } else {
        console.log("Registration is closed.");
        setRegistrationWindow(false);
      }
    } else {
      console.log("Registration dates are not valid.");
      setRegistrationWindow(false);
    }
  };

  const formatDate = (date) => {
    const options = {
      month: "numeric",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    const formattedDate = date.toLocaleString("en-US", options);
    const [datePart, timePart] = formattedDate.split(", ");
    const [month, day, year] = datePart.split("/");
    const [hours, minutes] = timePart.split(":");
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12;

    return `${month}/${day}/${year} ${formattedHours}:${minutes} ${ampm}`;
  };
  const fetchTournamentDetails = async () => {
    console.log("ID:", tourneyId);
    try {
      let formData = new FormData();
      formData.append("tourneyId", tourneyId);
      const response = await fetch(URL + "tournamentInformationAPI.php", {
        method: "POST",
        body: formData,
        credentials: "include",
      });

      console.log("Response Status:", response.status);
      // const responseBody = await response.text();
      // console.log("Response Body:", responseBody);

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.text();
      console.log("Server Response: ", response.status, data);
      const data2 = JSON.parse(data);
      if (data2.success) {
        console.log("Success");
        console.log("DATA 2:", data2);
        console.log("data2", data2.tournamentInformation);
        setTournamentDetails(data2.tournamentInformation);
        console.log("startdate: ", data2.tournamentInformation.startDate);
      } else {
        console.error("Failed");
        console.log("data2: ", data2);
      }
    } catch (error) {
      console.error("Failed: ", error.message);
    }
  };

  const convertUTCToLocalTime = (timeString) => {
    const utcTime = new Date(timeString);
    const localTimeOffset = utcTime.getTimezoneOffset() * 60000;
    const localTime = new Date(utcTime.getTime() - localTimeOffset);

    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      timeZoneName: "short",
    };

    return localTime.toLocaleString("en-US", options);
  };
  const makeStatusActive = async () => {
    console.log("tournamentID:", tourneyId);
    try {
      let formData = new FormData();
      formData.append("tournamentID", tourneyId);
      formData.append("active", 1);
      const response = await fetch(URL + "activateTournament.php", {
        method: "POST",
        body: formData,
        credentials: "include",
      });

      console.log("Response Status:", response.status);

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.text();
      console.log("Server Response: ", response.status, data);
      const data2 = JSON.parse(data);
      if (data2.success) {
        console.log("Success");
        alert("The tournament was made active");
        fetchTournamentDetails();
        console.log("DATA 2:", data2);
      } else {
        console.error("Failed to activate tourney");
        alert("Failed to activate tourney.");
        console.log("data2: ", data2);
      }
    } catch (error) {
      console.error("Failed: ", error.message);
      alert("Failed: ", error.message);
    }
  };
  const makeStatusInactive = async () => {
    console.log("tournamentID:", tourneyId);
    try {
      let formData = new FormData();
      formData.append("tournamentID", tourneyId);
      formData.append("active", 0);
      const response = await fetch(URL + "activateTournament.php", {
        method: "POST",
        body: formData,
        credentials: "include",
      });

      console.log("Response Status:", response.status);

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.text();
      console.log("Server Response: ", response.status, data);
      const data2 = JSON.parse(data);
      if (data2.success) {
        console.log("Success");
        alert("The tournament was made inactive");
        fetchTournamentDetails();
      } else {
        console.error("Failed to inactivate tourney");
        console.log("data2: ", data2);
        alert("Failed ot inactive tourney.");
      }
    } catch (error) {
      console.error("Failed: ", error.message);
      alert("Failed: ", error.message);
    }
  };
  const handleNo = () => {
    alert("No changes made");
  };
  const handleYes = () => {
    if (tournamentDetails.active === 0) {
      makeStatusActive();
    }
    if (tournamentDetails.active === 1) {
      makeStatusInactive();
    } else {
      console.log("error");
    }
  };
  return (
    <div className="content">
      <h1 className="tournaments-heading">Tournament Details:</h1>
      {tournamentDetails ? (
        <div className="content">
          <p className="tournamentDetailItem">
            <strong>Tournament Name: </strong>
            {tournamentDetails.tourneyName}
          </p>
          <p className="tournamentDetailItem">
            <strong>Description:</strong> {tournamentDetails.description}
          </p>
          <p className="tournamentDetailItem">
            <strong>Tournament Location Name:</strong>{" "}
            {tournamentDetails.siteName}
          </p>
          <p className="tournamentDetailItem">
            <strong>Tournament Location Address:</strong>{" "}
            {tournamentDetails.Address}
          </p>

          <p className="tournamentDetailItem">
            <strong>Tournament Start Date: </strong>
            {convertUTCToLocalTime(tournamentDetails.startDate)}
          </p>
          <p className="tournamentDetailItem">
            <strong> Tournament End Date: </strong>
            {convertUTCToLocalTime(tournamentDetails.endDate)}
          </p>
          <p className="tournamentDetailItem">
            <strong> Tournament Registration Start Date: </strong>
            {convertUTCToLocalTime(tournamentDetails.regStartDate)}
          </p>
          <p className="tournamentDetailItem">
            <strong> Tournament Registration End Date: </strong>
            {convertUTCToLocalTime(tournamentDetails.regEndDate)}
          </p>
          <p className="tournamentDetailItem">
            <strong> Tournament Type: </strong> {tournamentDetails.typeName}
          </p>
          {registrationWindow ? (
            <Link to={`/coachReg/${tournamentDetails.tourneyId}`}>
              <p className="tournamentDetailItem"> Click here to register</p>
            </Link>
          ) : (
            <Link to={`/coachReg/${tournamentDetails.tourneyId}`}>
              <p className="tournamentDetailItem">
                Registration Not Open. (Click here to register)
              </p>
            </Link>
          )}
          <Link to={`/adminEntryList/${tournamentDetails.tourneyId}`}>
            <p className="tournamentDetailItem">
              Click here to view the entry list
            </p>
          </Link>
          <Link to={`/tourneyResults/${tournamentDetails.tourneyId}`}>
            <p className="tournamentDetailItem"></p>
            Click here to view the results
          </Link>
          <Link to={`/scoring/${tournamentDetails.tourneyId}`}>
            <p className="tournamentDetailItem">Click here to add scores</p>
          </Link>
          <Link to={`/adminUpdateTourney/${tournamentDetails.tourneyId}`}>
            <p className="adminUpdateTourney">
              Click here to change tournament details
            </p>
          </Link>
          {/* <Link to={`/adminTourneySponsors/${tournamentDetails.tourneyId}`}>
            <p>Click here to view tourney sponsors</p>
          </Link> */}
          <p className="tournamentDetailItem">
            <strong> The tournament is currently: </strong>
            {tournamentDetails.active === 1 ? "active" : "inactive"}
          </p>
          {tournamentDetails.active === 0 && (
            <div>
              <label>Would you like to make the tournament active?</label>
              <button onClick={() => handleYes()}>Yes</button>
              <button onClick={() => handleNo()}>No</button>
            </div>
          )}
          {tournamentDetails.active === 1 && (
            <div>
              <label>Would you like to make the tournament inactive?</label>
              <button onClick={() => handleYes()}>Yes</button>
              <button onClick={() => handleNo()}>No</button>
            </div>
          )}
        </div>
      ) : (
        <p className="tournamentDetailItem">Loading...</p>
      )}
    </div>
  );
};
export default AdminTournamentDetails;
