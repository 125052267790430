import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { URL } from "../components/URL";
const Registration = () => {
  const [shooterName, setShooterName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNum, setPhoneNum] = useState("");
  const [affiliation, setAffiliation] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [DOB, setDOB] = useState("");
  const { tourneyId } = useParams();
  const [classes, setClasses] = useState([]);
  const [selectedClassId, setSelectedClassId] = useState("");
  const [selectedClassCost, setSelectedClassCost] = useState("");
  const [isPublic, setIsPublic] = useState(null);
  const [privateCode, setPrivateCode] = useState("");
  useEffect(() => {
    callCreateRegFormAPI();
  }, []);

  const callCreateRegFormAPI = async () => {
    // add if statements with else at the end
    try {
      let formData = new FormData();
      // check the API names
      formData.append("tournamentID", tourneyId);
      console.log(formData);

      const response = await fetch(URL + "createRegFormAPI.php", {
        method: "POST",
        body: formData,
        credentials: "include",
      });

      console.log("Response Status:", response.status);
      // const responseBody = await response.text();
      // console.log("Response Body:", responseBody);

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.text();
      console.log("Server Response: ", response.status, data);
      const data2 = JSON.parse(data);
      if (data2.success) {
        console.log("successful");
        console.log("LOOK at this: ", data2);
        setIsPublic(data2.public);
        setClasses(data2.typeClasses);
        console.log("classseeeeeeeeeeeeee:", classes);
        // might need to navigate somewhere else idk
      } else {
        console.error("Form submission failed");
        console.log("data2: ", data2);
      }
    } catch (error) {
      console.error("Error during form submission: ", error.message);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // add if statements with else at the end
    try {
      let formData = new FormData();
      // check the API names
      if (isPublic == 0) {
        formData.append("code", privateCode);
      }

      formData.append("tourneyID", tourneyId);
      formData.append("name", shooterName);
      formData.append("DOB", DOB);
      formData.append("email", email);
      formData.append("phoneNum", phoneNum);
      formData.append("affiliation", affiliation);
      formData.append("street", street);
      formData.append("city", city);
      formData.append("state", state);
      formData.append("zipCode", zipCode);
      formData.append("classID", selectedClassId);
      formData.append("isPublic", isPublic);
      console.log("selected class:", selectedClassId);
      //fix this
      //formData.append("classID", 27);
      console.log(formData);

      const response = await fetch(URL + "participantRegistrationAPI.php", {
        method: "POST",
        body: formData,
        credentials: "include",
      });

      console.log("Response Status:", response.status);
      // const responseBody = await response.text();
      // console.log("Response Body:", responseBody);

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.text();
      console.log("Server Response: ", response.status, data);
      const data2 = JSON.parse(data);
      if (data2.success) {
        console.log("Form submission successful");
        alert("Registration Submitted!!");
        // might need to navigate somewhere else idk
        setTimeout(() => {
          window.location.replace(URL + "tournament");
        }, 1000);
      } else {
        console.error("Form submission failed");
        console.log("data2: ", data2);
        alert("Form submission failed.");
      }
    } catch (error) {
      console.error("Error during form submission: ", error.message);
      alert("Error during form submission: ", error.message);
    }
  };

  const handleClassChange = (event) => {
    const selectedId = parseInt(event.target.value);
    console.log("Selected ID type:", typeof selectedId);
    console.log("Selected ID value:", selectedId);
    setSelectedClassId(selectedId);
    console.log("Classes array:", classes);
    const selectedClass = classes.find((classItem) => {
      console.log("Class ID type:", typeof classItem.classId);
      console.log("Class ID value:", classItem.classId);
      return classItem.classId === selectedId;
    });
    console.log("Selected class:", selectedClass);
    if (selectedClass) {
      setSelectedClassCost(selectedClass.classCost);
      console.log("Selected class cost:", selectedClass.classCost);
    } else {
      setSelectedClassCost("");
      console.log("Selected class not found");
    }
  };
  return (
    <div>
      <h1 className="tournaments-heading" style={{ textAlign: "center" }}>
        Registration:
      </h1>
      <div className="topSpaceContainer"></div>
      {/* Fix this and put it in a css file */}
      <div style={{ textAlign: "center" }}>
        <Link to={`/bulkRegistration/${tourneyId}`}>
          <p>Do you want to register more than one archer? Click here.</p>
        </Link>
      </div>
      <form onSubmit={handleSubmit}>
        {isPublic === 0 ? (
          <>
            <label htmlFor="privateCode">Enter Private Code:</label>
            <input
              type="text"
              id="privateCode"
              value={privateCode}
              onChange={(e) => setPrivateCode(e.target.value)}
              required
            />
          </>
        ) : null}
        <label htmlFor="shooterName">Name:</label>
        <input
          type="text"
          id="shooterName"
          value={shooterName}
          onChange={(e) => setShooterName(e.target.value)}
          required
        />

        <label htmlFor="DOB">Date of Birth:</label>
        <input
          type="date"
          id="DOB"
          value={DOB}
          onChange={(e) => setDOB(e.target.value)}
          required
        />
        <label htmlFor="email">Email Address:</label>
        <input
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <label htmlFor="phoneNum">Phone Number:</label>
        <PhoneInput
          country={"us"} // Set default country to United States
          value={phoneNum}
          onChange={(value) => setPhoneNum(value)}
          inputprops={{
            id: "phoneNum",
            required: true,
          }}
        />

        <label htmlFor="affiliation">Affiliation (optional):</label>
        <input
          type="text"
          id="affiliation"
          value={affiliation}
          onChange={(e) => setAffiliation(e.target.value)}
        />
        <p>Class:</p>
        <select value={selectedClassId} onChange={handleClassChange}>
          <option value="">Select a class</option>
          {classes.map((classItem) => (
            <option key={classItem.classId} value={classItem.classId}>
              {classItem.description}
            </option>
          ))}
        </select>
        <p>
          Cost: ${selectedClassCost ? (selectedClassCost / 100).toFixed(2) : ""}
        </p>

        <label htmlFor="street">Street:</label>
        <input
          type="text"
          id="street"
          value={street}
          onChange={(e) => setStreet(e.target.value)}
          required
        />
        <label htmlFor="city">City:</label>
        <input
          type="text"
          id="city"
          value={city}
          onChange={(e) => setCity(e.target.value)}
          required
        />

        <label htmlFor="state">State:</label>
        <select
          id="state"
          value={state}
          onChange={(e) => setState(e.target.value)}
          required
        >
          <option value="AL">AL</option>
          <option value="AK">AK</option>
          <option value="AZ">AZ</option>
          <option value="AR">AR</option>
          <option value="CA">CA</option>
          <option value="CO">CO</option>
          <option value="CT">CT</option>
          <option value="DE">DE</option>
          <option value="FL">FL</option>
          <option value="GA">GA</option>
          <option value="HI">HI</option>
          <option value="ID">ID</option>
          <option value="IL">IL</option>
          <option value="IN">IN</option>
          <option value="IA">IA</option>
          <option value="KS">KS</option>
          <option value="KY">KY</option>
          <option value="LA">LA</option>
          <option value="ME">ME</option>
          <option value="MD">MD</option>
          <option value="MA">MA</option>
          <option value="MI">MI</option>
          <option value="MN">MN</option>
          <option value="MS">MS</option>
          <option value="MO">MO</option>
          <option value="MT">MT</option>
          <option value="NE">NE</option>
          <option value="NV">NV</option>
          <option value="NH">NH</option>
          <option value="NJ">NJ</option>
          <option value="NM">NM</option>
          <option value="NY">NY</option>
          <option value="NC">NC</option>
          <option value="ND">ND</option>
          <option value="OH">OH</option>
          <option value="OK">OK</option>
          <option value="OR">OR</option>
          <option value="PA">PA</option>
          <option value="RI">RI</option>
          <option value="SC">SC</option>
          <option value="SD">SD</option>
          <option value="TN">TN</option>
          <option value="TX">TX</option>
          <option value="UT">UT</option>
          <option value="VT">VT</option>
          <option value="VA">VA</option>
          <option value="WA">WA</option>
          <option value="WV">WV</option>
          <option value="WI">WI</option>
          <option value="WY">WY</option>
        </select>
        <label htmlFor="zipCode">Zip Code:</label>
        <input
          type="text"
          id="zipCode"
          value={zipCode}
          onChange={(e) => setZipCode(e.target.value)}
          pattern="[0-9]{5}"
          title="Please enter a valid 5-digit zip code"
          required
        />

        <button type="submit">Register</button>
      </form>
    </div>
  );
};

export default Registration;
