import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import "./adminCreateTourney.css";
import { URL } from "../components/URL";
import TournamentDetails from "./tournamentDetails";
const AdminUpdateTourney = () => {
  // Still need to fill in the current values
  const { tourneyId } = useParams();
  const [tournamentName, setTournamentName] = useState("");
  const [tourneyStartDate, setTourneyStartDate] = useState("");
  const [tourneyEndDate, setTourneyEndDate] = useState("");
  const [registrationStartDate, setRegistrationStartDate] = useState("");
  const [registrationEndDate, setRegistrationEndDate] = useState("");
  const [tournamentType, setTournamentType] = useState("");
  const [location, setLocation] = useState("");
  const [customLocationName, setCustomLocationName] = useState("");
  const [customLocationAddress, setCustomLocationAddress] = useState("");
  const [isPublic, setIsPublic] = useState(true);
  const [privateCode, setPrivateCode] = useState("");
  const [tourneyStartDateUTC, seTourneyStartDateUTC] = useState("");
  const [tourneyEndDateUTC, setTourneyEndDateUTC] = useState("");
  const [registrationStartDateUTC, setRegistrationStartDateUTC] = useState("");
  const [registrationEndDateUTC, setRegistrationEndDateUTC] = useState("");
  const [scorerPassword, setScorerPassword] = useState("");
  const [rounds, setRounds] = useState("");
  const [description, setDescription] = useState("");
  const [tournamentDetails, setTournamentDetails] = useState(null);
  useEffect(() => {
    fetchTournamentDetails();
  }, []);
  const convertUTCToLocalTime = (timeString) => {
    const utcDate = new Date(timeString);
    const localDate = new Date(
      utcDate.getTime() - utcDate.getTimezoneOffset() * 60000
    );
    const year = localDate.getFullYear();
    const month = String(localDate.getMonth() + 1).padStart(2, "0");
    const day = String(localDate.getDate()).padStart(2, "0");
    const hours = String(localDate.getHours()).padStart(2, "0");
    const minutes = String(localDate.getMinutes()).padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };
  const fetchTournamentDetails = async () => {
    console.log("ID:", tourneyId);
    try {
      let formData = new FormData();
      formData.append("tourneyId", tourneyId);
      const response = await fetch(URL + "tournamentInformationAPI.php", {
        method: "POST",
        body: formData,
        credentials: "include",
      });

      console.log("Response Status:", response.status);
      // const responseBody = await response.text();
      // console.log("Response Body:", responseBody);

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.text();
      console.log("Server Response: ", response.status, data);
      const data2 = JSON.parse(data);
      if (data2.success) {
        console.log("Success");
        console.log("DATA 2:", data2);
        console.log("data2", data2.tournamentInformation);
        setTournamentDetails(data2.tournamentInformation);
        setTournamentName(data2.tournamentInformation.tourneyName);
        setRegistrationStartDate(data2.tournamentInformation.regStartDate);

        setRegistrationEndDate(data2.tournamentInformation.regEndDate);
        setTourneyStartDate(data2.tournamentInformation.startDate);
        setTourneyEndDate(data2.tournamentInformation.endDate);
        setTournamentType(data2.tournamentInformation.typeName);
        setRounds(data2.tournamentInformation.numRounds);
        setLocation(data2.tournamentInformation.siteName);
        setDescription(data2.tournamentInformation.description);

        console.log("startdate: ", data2.tournamentInformation.startDate);
      } else {
        console.error("Failed");
        console.log("data2: ", data2);
      }
    } catch (error) {
      console.error("Failed: ", error.message);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (tourneyStartDate > tourneyEndDate) {
      alert("The tournament start date is after the tournament end date.");
      return;
    }
    if (registrationStartDate > registrationEndDate) {
      alert("The registration start date is after the registration end date.");
      return;
    }
    if (registrationEndDate > tourneyStartDate) {
      alert("The registration end date is after the tournament start date.");
      return;
    }
    if (isPublic === false && privateCode === "") {
      alert("Failed to enter a code for the private tournament.");
      return;
    }
    if (isPublic === false && privateCode.length < 10) {
      alert("Private code must be atleast 10 characters.");
      return;
    }
    if (tournamentName === "") {
      alert("Failed to enter a name for the tournament.");
      return;
    }
    if (tourneyStartDate === "") {
      alert("Failed to enter a tournament start date.");
      return;
    }
    if (tourneyEndDate === "") {
      alert("Failed to enter a tournament end date.");
      return;
    }
    if (registrationStartDate === "") {
      alert("Failed to enter a registration start date.");
      return;
    }
    if (registrationEndDate === "") {
      alert("Failed to enter a registration end date.");
      return;
    }
    if (tournamentType === "") {
      alert("Failed to enter a tournament type.");
      return;
    }
    if (location === "") {
      alert("Failed to enter a location.");
      return;
    }

    if (location === "other" && customLocationName === "") {
      alert("Failed to enter a name for the custom location.");
      return;
    }
    if (location === "other" && customLocationAddress === "") {
      alert("Failed to enter an address for the custom location.");
      return;
    }
    if (scorerPassword === null) {
      alert("Please enter a scorer password");
      return;
    } else {
      try {
        let formData = new FormData();
        const result = await changeTimeUTC();
        formData.append("tourneyID", tourneyId);
        formData.append("tournament", tournamentName);
        formData.append("tourneyStartDate", result.tourneyStartDate);
        formData.append("tourneyEndDate", result.tourneyEndDate);
        formData.append("registrationDateStart", result.registrationStartDate);
        formData.append("registrationDateEnd", result.registrationEndDate);
        formData.append("tournamentType", tournamentType);
        formData.append("location", location);
        formData.append("rounds", rounds);

        formData.append("scorerPW", scorerPassword);
        formData.append("description", description);
        formData.append("isPublic", isPublic);
        if (location === "other") {
          formData.append("customLocationName", customLocationName);
          formData.append("customLocationAddress", customLocationAddress);
        }
        if (isPublic === false) {
          formData.append("privateCode", privateCode);
        }
        console.log(formData);

        const response = await fetch(URL + "changeTournamentDetailsAPI.php", {
          method: "POST",
          body: formData,
          credentials: "include",
        });

        console.log("Response Status:", response.status);
        // const responseBody = await response.text();
        // console.log("Response Body:", responseBody);

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.text();
        console.log("Server Response: ", response.status, data);
        const data2 = JSON.parse(data);
        if (data2.success) {
          console.log("Form submission successful");
          alert("Tournament Updated!!");
          // might need to navigate somewhere else idk
          setTimeout(() => {
            window.location.replace(URL + "adminTourneys");
          }, 1000);
        } else {
          console.error("Form submission failed");
          console.log("data2: ", data2);
          alert("Form submission failed.");
        }
      } catch (error) {
        console.error("Error during form submission: ", error.message);
        alert("Error during form submission: ", error.message);
      }
    }
  };
  const changeTimeUTC = async () => {
    // For tournament start date
    const startDate = new Date(tourneyStartDate);
    const startUtcHours = startDate.getUTCHours();
    const startUtcMinutes = startDate.getUTCMinutes();
    console.log("UTC Start Time:", startUtcHours, ":", startUtcMinutes);
    setTourneyStartDate(
      new Date(
        Date.UTC(
          startDate.getFullYear(),
          startDate.getMonth(),
          startDate.getDate(),
          startUtcHours,
          startUtcMinutes
        )
      )
    );
    console.log("Tournament Start Time: ", startDate);

    // For tournament end date
    const endDate = new Date(tourneyEndDate);
    const endUtcHours = endDate.getUTCHours();
    const endUtcMinutes = endDate.getUTCMinutes();
    console.log("UTC End Time:", endUtcHours, ":", endUtcMinutes);
    setTourneyEndDate(
      new Date(
        Date.UTC(
          endDate.getFullYear(),
          endDate.getMonth(),
          endDate.getDate(),
          endUtcHours,
          endUtcMinutes
        )
      )
    );
    console.log("Tournament End Time: ", endDate);

    // For registration start date
    const regStartDate = new Date(registrationStartDate);
    const regStartUtcHours = regStartDate.getUTCHours();
    const regStartUtcMinutes = regStartDate.getUTCMinutes();
    console.log(
      "UTC Registration Start Time:",
      regStartUtcHours,
      ":",
      regStartUtcMinutes
    );
    setRegistrationStartDate(
      new Date(
        Date.UTC(
          regStartDate.getFullYear(),
          regStartDate.getMonth(),
          regStartDate.getDate(),
          regStartUtcHours,
          regStartUtcMinutes
        )
      )
    );
    console.log("Registration Start Time: ", regStartDate);

    // For registration end date
    const regEndDate = new Date(registrationEndDate);
    const regEndUtcHours = regEndDate.getUTCHours();
    const regEndUtcMinutes = regEndDate.getUTCMinutes();
    console.log(
      "UTC Registration End Time:",
      regEndUtcHours,
      ":",
      regEndUtcMinutes
    );
    setRegistrationEndDate(
      new Date(
        Date.UTC(
          regEndDate.getFullYear(),
          regEndDate.getMonth(),
          regEndDate.getDate(),
          regEndUtcHours,
          regEndUtcMinutes
        )
      )
    );
    console.log("Registration End Time: ", regEndDate);

    return {
      tourneyStartDate: formatTime(startDate),
      tourneyEndDate: formatTime(endDate),
      registrationStartDate: formatTime(regStartDate),
      registrationEndDate: formatTime(regEndDate),
    };
  };
  const formatTime = (date) => {
    const year = date.getUTCFullYear();
    // jan. starts at 0, so I'm adding 1
    const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
    const day = date.getUTCDate().toString().padStart(2, "0");
    const hours = date.getUTCHours().toString().padStart(2, "0");
    const minutes = date.getUTCMinutes().toString().padStart(2, "0");
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };
  return (
    <div>
      {/* <h1>Welcome to the Create Tourney Page!</h1> */}
      <div className="topSpaceContainer"></div>
      <form onSubmit={handleSubmit}>
        <label htmlFor="scorerPassword">Scorer Password:</label>
        <input
          type="text"
          id="scorerPassword"
          value={scorerPassword}
          onChange={(e) => setScorerPassword(e.target.value)}
          required
        />
        <label htmlFor="tournamentName">Tournament Name:</label>
        {tournamentDetails && (
          <input
            type="text"
            id="tournamentName"
            defaultValue={tournamentDetails.tourneyName}
            onChange={(e) => setTournamentName(e.target.value)}
            required
          />
        )}
        <label htmlFor="description">Tournament Description:</label>
        {tournamentDetails && (
          <input
            type="text"
            id="description"
            defaultValue={tournamentDetails.description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />
        )}

        <label htmlFor="tourneyStartDate">
          Tournament Start Date and Time:
        </label>
        {tournamentDetails && (
          <input
            type="datetime-local"
            id="tourneyStartDate"
            defaultValue={convertUTCToLocalTime(tournamentDetails.startDate)}
            onChange={(e) => setTourneyStartDate(e.target.value)}
            required
          />
        )}

        <label htmlFor="tourneyEndDate">Tournament End Date and Time:</label>
        {tournamentDetails && (
          <input
            type="datetime-local"
            id="tourneyEndDate"
            defaultValue={convertUTCToLocalTime(tournamentDetails.endDate)}
            onChange={(e) => setTourneyEndDate(e.target.value)}
            required
          />
        )}
        <label htmlFor="registrationStartDate">
          Registration Start Date and Time:
        </label>
        {tournamentDetails && (
          <input
            type="datetime-local"
            id="registrationStartDate"
            defaultValue={convertUTCToLocalTime(tournamentDetails.regStartDate)}
            onChange={(e) => setRegistrationStartDate(e.target.value)}
            required
          />
        )}
        <label htmlFor="registrationEndDate">
          Registration End Date and Time:
        </label>
        {tournamentDetails && (
          <input
            type="datetime-local"
            id="registrationEndDate"
            defaultValue={convertUTCToLocalTime(tournamentDetails.regEndDate)}
            onChange={(e) => setRegistrationEndDate(e.target.value)}
            required
          />
        )}

        <label htmlFor="tournamentType">Tournament Type:</label>
        {tournamentDetails && (
          <select
            id="tournamentType"
            defaultValue={tournamentDetails.typeName}
            onChange={(e) => setTournamentType(e.target.value)}
            required
          >
            <option value="">Select Tournament Type</option>
            <option value="ASA3D">ASA 3D</option>
            <option value="Collegiate3D">Collegiate 3D</option>
            <option value="IndoorTarget">Indoor Target</option>
            <option value="OutdoorTarget">Outdoor Target</option>
          </select>
        )}
        <label htmlFor="rounds"># of Rounds:</label>
        {tournamentDetails && (
          <input
            type="text"
            id="rounds"
            defaultValue={tournamentDetails.numRounds}
            onChange={(e) => setRounds(e.target.value)}
            required
          />
        )}
        <label htmlFor="location">Location:</label>
        {tournamentDetails && (
          <select
            id="location"
            defaultValue={tournamentDetails.siteName}
            onChange={(e) => setLocation(e.target.value)}
            required
          >
            <option value="JG Gym">JG Gym</option>
            <option value="MooreheadFarm">Moorehead Farm</option>
            <option value="BHWC">BHWC</option>
            <option value="other">Other</option>
          </select>
        )}

        {location === "other" && (
          <input
            type="text"
            placeholder="Enter the name for the location"
            value={customLocationName}
            onChange={(e) => setCustomLocationName(e.target.value)}
          />
        )}
        {location === "other" && (
          <input
            type="text"
            placeholder="Enter an address for the location"
            value={customLocationAddress}
            onChange={(e) => setCustomLocationAddress(e.target.value)}
          />
        )}
        <label htmlFor="isPublic">
          Public/Private Tourney. Public is default:
        </label>
        <select
          id="isPublic"
          value={isPublic}
          onChange={(e) => setIsPublic(e.target.value === "true")}
          required
        >
          <option value={true}>Public</option>
          <option value={false}>Private</option>
        </select>

        {isPublic === false && (
          <div>
            <label htmlFor="privateCode">Enter Private Code:</label>
            <input
              type="text"
              id="privateCode"
              value={privateCode}
              onChange={(e) => setPrivateCode(e.target.value)}
              required
            />
          </div>
        )}

        <button type="submit">Create Tournament</button>
      </form>
    </div>
  );
};

export default AdminUpdateTourney;
