// import logo from "./logo.svg";
import "./App.css";
import Navbar from "./components/Navbar";
// import Footer from "./components/Footer";
import React from "react";
import Home from "./components/home";
import AdminLogin from "./components/adminLogin";
import AdminLogout from "./components/adminLogout";
import Registration from "./components/registration";
import BulkRegistration from "./components/bulkRegistration";
import AdminCreateTourney from "./components/adminCreateTourney";
import Tournament from "./components/tournament";
import PastTournament from "./components/pastTournament";
import TournamentDetails from "./components/tournamentDetails";
import PastTournamentDetails from "./components/pastTournamentDetails";
import AdminTournamentDetails from "./components/adminTournamentDetails";
import CoachReg from "./components/coachReg.js";
import AdminTourneys from "./components/adminTourneys.js";
import AdminChangePassword from "./components/adminChangePassword.js";
import AdminClassCost from "./components/adminClassCost.js";
import Scoring from "./components/scoring.js";
import ShowEntryList from "./components/showEntryList.js";
import TourneyResults from "./components/tourneyResults.js";
import AddTourneySponsors from "./components/addTourneySponsors.js";
import AdminEntryList from "./components/adminEntryList.js";
import AddContent from "./components/addContent.js";
import AdminUpdateTourney from "./components/adminUpdateTourney.js";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {
  return (
    <>
      <Router>
        <Navbar />

        <Routes>
          <Route path="/home" element={<Home />} />
          <Route path="/adminLogin" element={<AdminLogin />} />
          <Route
            path="/adminChangePassword"
            element={<AdminChangePassword />}
          />
          <Route path="/adminLogout" element={<AdminLogout />} />
          <Route path="/adminCreateTourney" element={<AdminCreateTourney />} />
          <Route path="/tournament" element={<Tournament />} />
          <Route path="/pastTournament" element={<PastTournament />} />
          <Route path="/adminTourneys" element={<AdminTourneys />} />
          <Route path="/addContent" element={<AddContent />} />
          <Route
            path="/tournamentDetails/:tourneyId"
            element={<TournamentDetails />}
          />
          <Route
            path="/tourneyResults/:tourneyId"
            element={<TourneyResults />}
          />
          <Route
            path="/pastTournamentDetails/:tourneyId"
            element={<PastTournamentDetails />}
          />
          <Route
            path="/adminTournamentDetails/:tourneyId"
            element={<AdminTournamentDetails />}
          />
          <Route
            path="/addTourneySponsors/:tourneyId"
            element={<AddTourneySponsors />}
          />
          <Route
            path="/adminUpdateTourney/:tourneyId"
            element={<AdminUpdateTourney />}
          />
          <Route
            path="/adminClassCost/:tourneyId"
            element={<AdminClassCost />}
          />
          <Route path="/scoring/:tourneyId" element={<Scoring />} />
          <Route path="/showEntryList/:tourneyId" element={<ShowEntryList />} />
          <Route path="/registration/:tourneyId" element={<Registration />} />
          <Route
            path="/bulkRegistration/:tourneyId"
            element={<BulkRegistration />}
          />
          <Route
            path="/adminEntryList/:tourneyId"
            element={<AdminEntryList />}
          />
          <Route path="/coachReg/:tourneyId" element={<CoachReg />} />
        </Routes>
        {/* <Footer /> */}
      </Router>
    </>
  );
}

export default App;
