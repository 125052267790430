import React, { useState, useEffect } from "react";
import "./styles.css";
import { useParams } from "react-router-dom";
import { URL } from "../components/URL";
const Scoring = () => {
  const { tourneyId } = useParams();
  const [scorerPassword, setScorerPassword] = useState("");
  const [score, setScore] = useState("");
  const [newScore, setNewScore] = useState("");
  const [shooterIds, setShooterIds] = useState([]);
  const [shooters, setShooters] = useState([]);
  const [updatedShooters, setUpdatedShooters] = useState([]);
  const [scores, setScores] = useState([]);
  const [extras, setExtras] = useState([]);
  const [entryList, setEntryList] = useState([]);
  const [rounds, setRounds] = useState("");
  const [tournamentDetails, setTournamentDetails] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await callEntryListAPI();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  // fix the entryList here
  function initializeShootersData(entryList, extras) {
    const shootersData = entryList.map((entry) => ({
      shooterId: entry.shooterId,
      score: 0,
      extras: extras.map((extra) => ({ ...extra })),
    }));
    setShooters(shootersData);
    console.log("Shooterssss:", shootersData);
  }

  const callEntryListAPI = async () => {
    // add if statements with else at the end
    let importantData = null;
    let importantExtras = null;
    try {
      let formData = new FormData();

      // check the API names
      formData.append("tourneyID", tourneyId);
      console.log(formData);

      const response = await fetch(URL + "entryListAPI.php", {
        method: "POST",
        body: formData,
        credentials: "include",
      });

      console.log("Response Status:", response.status);

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.text();
      console.log("Server Response: ", response.status, data);
      const data2 = JSON.parse(data);

      if (data2.success) {
        console.log("successful");
        console.log("LOOK at this here: ", data2);

        setEntryList(data2.entryList);
        importantData = data2.entryList;
        console.log("entry list info: ", data2.entryList);

        const shootersData = data2.entryList.map((entry) => ({
          shooterId: entry[0],
          score: 0,
          //check this
          extras: [],
        }));

        setShooters(shootersData);
        console.log("Shooters12:", shootersData);
      } else {
        console.error("Form submission failed");
        console.log("data2: ", data2);
      }
    } catch (error) {
      console.error("Error: ", error.message);
    }
    console.log("ID:", tourneyId);
    try {
      let formData = new FormData();
      formData.append("tourneyId", tourneyId);
      const response = await fetch(URL + "tournamentInformationAPI.php", {
        method: "POST",
        body: formData,
        credentials: "include",
      });

      console.log("Response Status:", response.status);

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.text();
      console.log("Server Response: ", response.status, data);
      const data2 = JSON.parse(data);
      if (data2.success) {
        console.log("Success");
        console.log("DATA 2:", data2);
        console.log("data2", data2.tournamentInformation);
        setTournamentDetails(data2.tournamentInformation);
        const extrasData = data2.tournamentInformation.Extras.map(
          (extraItem) => ({
            extraValueId: extraItem.extraValueId,
            name: extraItem.value,
            value: null,
          })
        );
        importantExtras = extrasData;
        setExtras(extrasData);
        console.log("extras: ", extrasData);
        initializeShootersData(importantData, importantExtras);
      } else {
        console.error("Failed");
        console.log("data2: ", data2);
      }
    } catch (error) {
      console.error("Failed: ", error.message);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let formData = new FormData();
      console.log("Shooters", shooters);
      for (let shooter of shooters) {
        formData.append("shooters[]", JSON.stringify(shooter));
        console.log("BOJEC:", JSON.stringify(shooter));
      }
      //formData.append("shooters", shooters);
      formData.append("tourneyID", tourneyId);
      formData.append("scorerPassword", scorerPassword);
      formData.append("round", rounds);
      const response = await fetch(URL + "enteringScoresAPI.php", {
        method: "POST",
        body: formData,
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.text();
      console.log("Server Response: ", response.status, data);
      const data2 = JSON.parse(data);
      if (data2.success) {
        console.log("successful");
        console.log("LOOK at this: ", data2);
        alert("Scoring Submitted!!");
        setTimeout(() => {
          window.location.replace(URL + "home");
        }, 1000);
      } else {
        console.error("Form submission failed");
        console.log("data2: ", data2);
        alert("Form submission failed.");
      }
    } catch (error) {
      console.error("Error during form submission: ", error.message);
      alert("Error during form submission: ", error.message);
    }
  };
  const handleExtraChange = (shooterId, extraValueId, value) => {
    console.log("Shooter ID:", shooterId);
    console.log("Extra Value ID:", extraValueId);
    console.log("Value:", value);

    const updatedShooters = shooters.map((shooter) => {
      if (shooter.shooterId === shooterId) {
        const updatedExtras = shooter.extras.map((extra) => {
          if (extra.extraValueId === extraValueId) {
            return { ...extra, value: value };
          }
          return extra;
        });
        return { ...shooter, extras: updatedExtras };
      }
      return shooter;
    });

    console.log("Updated Shooters:", updatedShooters);

    setShooters(updatedShooters);
  };

  const handleScoreChange = (shooterIndex, newScore) => {
    const updatedShooters = [...shooters];
    for (let i = 0; i < updatedShooters.length; i++) {
      if (updatedShooters[i].shooterId === shooterIndex) {
        updatedShooters[i].score = newScore;
        break;
      }
    }
    setShooters(updatedShooters);
    console.log("Shooters in score change:", shooters);
  };
  return (
    <div>
      <div className="topSpaceContainer"></div>
      <form onSubmit={handleSubmit}>
        <label htmlFor="scorerPassword">Enter Scorer Password:</label>
        <input
          type="text"
          id="scorerPassword"
          value={scorerPassword}
          onChange={(e) => setScorerPassword(e.target.value)}
          required
        />
        <label htmlFor="rounds">Enter Round #:</label>
        <input
          type="text"
          id="rounds"
          value={rounds}
          onChange={(e) => setRounds(e.target.value)}
          required
        />
        <p>Entry List:</p>
        <div>
          {entryList.map((shooter, index) => (
            <div key={shooter.shooterId} style={{ marginBottom: "10px" }}>
              <span style={{ display: "inline-block", width: "150px" }}>
                {shooter.name}'s Score:
              </span>
              <input
                type="text"
                value={shooter.score}
                onChange={(e) =>
                  handleScoreChange(shooter.shooterId, e.target.value)
                }
              />
              {extras.length > 0 &&
                extras.map((extra, extraIndex) => (
                  <div key={extraIndex} style={{ marginLeft: "20px" }}>
                    <label htmlFor={`extra-${index}-${extraIndex}`}>
                      {extra.name}
                    </label>
                    <input
                      type="text"
                      id={`extra-${index}-${extraIndex}`}
                      value={extra.value}
                      onChange={(e) =>
                        handleExtraChange(
                          shooter.shooterId,
                          extra.extraValueId,
                          e.target.value
                        )
                      }
                    />
                  </div>
                ))}
            </div>
          ))}
        </div>
        <button type="submit">Submit Scores</button>
      </form>
    </div>
  );
};

export default Scoring;
