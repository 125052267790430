import React, { useState, useEffect } from "react";
import "./styles.css";
import { useParams } from "react-router-dom";
import { URL } from "../components/URL";
const ShowEntryList = () => {
  const { tourneyId } = useParams();
  const [entryList, setEntryList] = useState([]);
  useEffect(() => {
    callEntryListAPI();
  }, []);

  const callEntryListAPI = async () => {
    // add if statements with else at the end
    try {
      let formData = new FormData();
      formData.append("tourneyID", tourneyId);
      console.log(formData);

      const response = await fetch(URL + "entryListAPI.php", {
        method: "POST",
        body: formData,
        credentials: "include",
      });

      console.log("Response Status:", response.status);

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.text();
      console.log("Server Response: ", response.status, data);
      const data2 = JSON.parse(data);
      if (data2.success) {
        console.log("successful");
        console.log("LOOK at this: ", data2);
        setEntryList(data2.entryList);
        console.log("entry list info: ", data2.entryList);
      } else {
        console.error("Form submission failed");
        console.log("data2: ", data2);
      }
    } catch (error) {
      console.error("Error: ", error.message);
    }
  };
  function groupByClassName(entryList) {
    return entryList.reduce((acc, entry) => {
      const className = entry.description;
      if (!acc[className]) {
        acc[className] = [];
      }
      acc[className].push(entry);
      return acc;
    }, {});
  }
  return (
    <div>
      <div className="content">
        <h1 className="tournaments-heading">Entry List:</h1>

        {Object.entries(groupByClassName(entryList)).map(
          ([className, shooters]) => (
            <div key={className} className="classGroup">
              <h1 className="class-heading">{className}</h1>
              <h1 className="class-heading2">
                <span></span>
                <span>Name</span>
                <span></span>
                <span>Affiliation</span>
                <span></span>
              </h1>
              <ul className="tournamentListItem2">
                {shooters.map((shooter) => (
                  <li key={shooter.shooterId}>
                    <span>{shooter.name}</span>
                    <span>
                      {shooter.affiliation ? shooter.affiliation : "N/A"}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default ShowEntryList;
