import React, { useState, useEffect } from "react";
import "./styles.css";
import { useParams } from "react-router-dom";
import { URL } from "../components/URL";
const TourneyResults = () => {
  const { tourneyId } = useParams();
  const [shootersInfo, setShootersInfo] = useState([]);
  useEffect(() => {
    callResultsAPI();
  }, []);

  const callResultsAPI = async () => {
    try {
      let formData = new FormData();
      formData.append("tourneyID", tourneyId);
      console.log(formData);

      const response = await fetch(URL + "showResultsListAPI.php", {
        method: "POST",
        body: formData,
        credentials: "include",
      });

      console.log("Response Status:", response.status);

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.text();
      console.log("Server Response: ", response.status, data);
      const data2 = JSON.parse(data);
      if (response.status == 200) {
        console.log("successful");
        console.log("LOOK at this: ", data2);
        setShootersInfo(data2.shooters);
        console.log("shooters info: ", data2.shooters);
      } else {
        console.error("Form submission failed");
        console.log("data2: ", data2);
      }
    } catch (error) {
      console.error("Error: ", error.message);
    }
  };
  function groupByClassName(shooters) {
    return shooters.reduce((acc, shooter) => {
      const className = shooter.className;
      if (!acc[className]) {
        acc[className] = [];
      }
      acc[className].push(shooter);
      return acc;
    }, {});
  }
  const maxRounds = Math.max(
    ...shootersInfo.map((shooter) => shooter.scores.length)
  );

  const roundLabels = Array.from({ length: maxRounds }, (_, index) => {
    const roundNumber = index + 1;
    const roundName = shootersInfo[0]?.scores[index]?.roundName;
    return roundName ? roundName : `Round ${roundNumber}`;
  });
  const getRoundScore = (shooter, roundLabel) => {
    const roundNumber = parseInt(roundLabel.match(/\d+/)[0]);
    const score = shooter.scores.find((score) => score.round === roundNumber);
    return score ? score.score : "N/A";
  };
  const maxExtras = Math.max(
    ...shootersInfo.map((shooter) => shooter.extras.length)
  );

  const extraLabels = Array.from({ length: maxExtras }, (_, index) => {
    const extra = shootersInfo[0]?.extras[index]?.type;
    return extra ? `${extra}` : `Extra ${index + 1}`;
  });

  const getTotalScore = (shooter) => {
    const totalScore = shooter.scores.reduce(
      (total, score) => total + score.score,
      0
    );
    return totalScore;
  };

  const getExtraValue = (shooter, extraLabel) => {
    const extra = shooter.extras.find((extra) => extra.type === extraLabel);
    return extra ? extra.total : "";
  };
  return (
    <div>
      <div className="content">
        <h1 className="tournaments-heading">Results:</h1>

        {Object.entries(groupByClassName(shootersInfo)).map(
          ([className, shooters]) => (
            <div key={className} className="classGroup">
              <h1 className="class-heading">{className}</h1>
              <h1 className="class-heading2">
                <span>Name</span>
                <span>Affiliation</span>
                {roundLabels.map((label) => (
                  <span key={label}>{label}</span>
                ))}
                <span>Total Score</span>
                {extraLabels.map((label) => (
                  <span key={label}>{label}</span>
                ))}
                <span> </span>
              </h1>
              {shooters.map((shooter) => (
                <ul className="tournamentListItem2" key={shooter.shooterId}>
                  <li>
                    <span>{shooter.name}</span>
                    <span>
                      {shooter.affiliation ? shooter.affiliation : "N/A"}
                    </span>
                    <span> </span>
                    {roundLabels.map((label, index) => (
                      <span key={index}>{getRoundScore(shooter, label)}</span>
                    ))}
                    <span> </span>
                    <span>{getTotalScore(shooter)}</span>
                    <span> </span>
                    {extraLabels.map((label, index) => (
                      <span key={index}>{getExtraValue(shooter, label)}</span>
                    ))}
                    <span> </span>
                  </li>
                </ul>
              ))}
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default TourneyResults;
