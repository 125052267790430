import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import "./adminLogin.css";
import { URL } from "../components/URL";
const AdminChangePassword = () => {
  const [username, setUsername] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword1, setNewPassword1] = useState("");
  const [newPassword2, setNewPassword2] = useState("");
  const handleSubmit = async (e) => {
    e.preventDefault();
    if ((newPassword1 && newPassword2) == oldPassword) {
      alert("The new password and the old password are the same.");
    }
    if (newPassword1 == newPassword2) {
      if (newPassword1.length < 8) {
        alert("Password must be at least 8 characters long");
        return;
      }
      if (newPassword2.length < 8) {
        alert("Password must be at least 8 characters long");
        return;
      }
    } else {
      alert("New passwords do not match.");
    }
    if (username.length < 8) {
      alert("Username must be at least 8 characters long");
      return;
    } else {
      try {
        let formData = new FormData();
        formData.append("username", username);
        formData.append("oldPassword", oldPassword);
        formData.append("newPassword", newPassword1);
        console.log(formData);
        const response = await fetch(URL + "passwordChangeAPI.php", {
          method: "POST",
          body: formData,
          credentials: "include",
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.text();

        console.log("Server Response: ", response.status, data);
        const data2 = JSON.parse(data);
        if (data2.success) {
          console.log("Change password successful");
          alert("Change password Successful!!");
          setTimeout(() => {
            window.location.replace(URL + "adminLogin");
          }, 1000);
        } else {
          alert(
            "Change password failed. Username and/or passwords are incorrect."
          );
          console.error(
            "Change password failed. Username and/or passwords are incorrect."
          );
        }
      } catch (error) {
        console.error("Error during password change:", error.message);
        alert("Error during password change:", error.message);
      }
    }
  };
  return (
    <>
      <div className="topSpaceContainer"></div>
      <form className="loginFormContainer" onSubmit={handleSubmit}>
        {/*       
      <form className="loginFormContainer"> */}
        <label htmlFor="username">Username</label>
        <input
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          placeholder="yourusername@gmail.com"
          id="username"
          name="username"
        />
        <label htmlFor="password">Enter old password</label>
        <input
          value={oldPassword}
          onChange={(e) => setOldPassword(e.target.value)}
          placeholder="********"
          id="oldPassword"
          name="oldPassword"
        />
        <label htmlFor="password">Enter new password</label>
        <input
          value={newPassword1}
          onChange={(e) => setNewPassword1(e.target.value)}
          placeholder="********"
          id="newPassword1"
          name="newPassword1"
        />
        <label htmlFor="password">Re-enter new password</label>
        <input
          value={newPassword2}
          onChange={(e) => setNewPassword2(e.target.value)}
          placeholder="********"
          id="newPassword2"
          name="newPassword2"
        />
        <button type="submit">Change Password</button>
      </form>
    </>
  );
};

export default AdminChangePassword;
