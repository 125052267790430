import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import "./tournaments.css";
import { URL } from "../components/URL";
const PastTournamentDetails = () => {
  // helps get the tournament name from URL
  const { tourneyId } = useParams();
  const [tournamentDetails, setTournamentDetails] = useState(null);

  useEffect(() => {
    const fetchTournamentDetails = async () => {
      console.log("ID:", tourneyId);
      try {
        let formData = new FormData();
        formData.append("tourneyId", tourneyId);
        const response = await fetch(URL + "tournamentInformationAPI.php", {
          method: "POST",
          body: formData,
          credentials: "include",
        });

        console.log("Response Status:", response.status);
        // const responseBody = await response.text();
        // console.log("Response Body:", responseBody);

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.text();
        console.log("Server Responseeee: ", response.status, data);
        const data2 = JSON.parse(data);
        if (data2.success) {
          console.log("Success");
          console.log("data2", data2.tournamentInformation);
          setTournamentDetails(data2.tournamentInformation);
          console.log("tournament details:", tournamentDetails);

          console.log("tournament details:", data2.tournamentInformation);
        } else {
          console.error("Failed");
          console.log("data2: ", data2);
        }
      } catch (error) {
        console.error("Failed: ", error.message);
      }
    };

    fetchTournamentDetails();
  }, [tourneyId]);
  const convertUTCToLocalTime = (timeString) => {
    const utcTime = new Date(timeString);
    const localTimeOffset = utcTime.getTimezoneOffset() * 60000;
    const localTime = new Date(utcTime.getTime() - localTimeOffset);

    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      timeZoneName: "short",
    };

    return localTime.toLocaleString("en-US", options);
  };
  return (
    <div className="content">
      <h1 className="tournaments-heading">Past Tournament Details:</h1>
      {tournamentDetails ? (
        <div className="content">
          <ul className="tournamentList">
            <li className="tournamentListItem">
              <strong>Tournament Name:</strong> {tournamentDetails.tourneyName}
            </li>
            <p className="tournamentDetailItem">
              <strong>Description:</strong> {tournamentDetails.description}
            </p>
            <li className="tournamentListItem">
              <strong>Tournament Location Name:</strong>{" "}
              {tournamentDetails.siteName}
            </li>
            <li className="tournamentListItem">
              <strong>Tournament Location Address:</strong>{" "}
              {tournamentDetails.Address}
            </li>
            <li className="tournamentListItem">
              <strong>Tournament Start Date:</strong>{" "}
              {convertUTCToLocalTime(tournamentDetails.startDate)}
            </li>
            <li className="tournamentListItem">
              <strong>Tournament End Date:</strong>{" "}
              {convertUTCToLocalTime(tournamentDetails.endDate)}
            </li>
            <li className="tournamentListItem">
              <strong>Tournament Registration Start Date:</strong>{" "}
              {convertUTCToLocalTime(tournamentDetails.regStartDate)}
            </li>
            <li className="tournamentListItem">
              <strong>Tournament Registration End Date:</strong>{" "}
              {convertUTCToLocalTime(tournamentDetails.regEndDate)}
            </li>
            <li className="tournamentListItem">
              <strong>Tournament Type:</strong> {tournamentDetails.typeName}
            </li>
            <li className="tournamentListItem">
              <Link to={`/tourneyResults/${tournamentDetails.tourneyId}`}>
                <strong>Click here to view the results</strong>
              </Link>
            </li>
          </ul>
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default PastTournamentDetails;
