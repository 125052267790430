import React, { useRef, useState, useEffect } from "react";
import archery2 from "../images/archery2.jpg";
import archery3 from "../images/archery3.jpg";
import archery4 from "../images/archery4.jpg";
import archery5 from "../images/archery5.jpg";
import { URL } from "../components/URL";
import "./home.css";

const Home = () => {
  const [contentID, setContentID] = useState(1);
  const [content, setContent] = useState("");
  useEffect(() => {
    callContentFetch();
  }, []);

  const callContentFetch = async () => {
    // add if statements with else at the end
    try {
      let formData = new FormData();
      // check the API names
      formData.append("contentID", 1);
      console.log(formData);

      const response = await fetch(URL + "contentFetchAPI.php", {
        method: "POST",
        body: formData,
        credentials: "include",
      });

      console.log("Response Status:", response.status);

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.text();
      console.log("Server Response: ", response.status, data);
      const data2 = JSON.parse(data);
      if (data2.success) {
        console.log("successful");
        console.log("LOOK at this: ", data2);
        setContent(data2.content.textVal);
        console.log(data2.content.textVal);
      } else {
        console.error("Form submission failed");
        console.log("data2: ", data2);
      }
    } catch (error) {
      console.error("Error during form submission: ", error.message);
    }
  };

  return (
    <div className="image-container">
      <img
        src="https://muarchery.jayshaffstall.com/sponsorImage/1_resized.jpeg"
        alt="Muskingum Archery 3"
      />
      <div className="content-container">{content && <p>{content}</p>}</div>
    </div>
  );
};
// s
export default Home;
