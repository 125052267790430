import React, { useState } from "react";
import { URL } from "../components/URL";
const AddContent = () => {
  const [contentId, setContentId] = useState("");
  const [content, setContent] = useState("");
  const [sponsorId, setSponsorId] = useState("");
  const [sponsorImg, setSponsorImg] = useState(null);

  const handleSubmit = async (e) => {
    console.log("SDfSF");
    //try {
    let formData = new FormData();
    if (contentId === "" || contentId == null) {
      console.log("Content ID is null.");
      return;
    }
    if (content === "" || content == null) {
      console.log("Content text is null.");
      return;
    }
    console.log("contentID: ", contentId);
    formData.append("contentID", contentId);
    formData.append("content", content);
    //console.log("here");
    const response = await fetch(URL + "updateContentAPI.php", {
      method: "POST",
      body: formData,
      credentials: "include",
    });

    console.log("Response Status:", response.status);

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.text();
    console.log("Server Response: ", response.status, data);
    const data2 = JSON.parse(data);
    if (data2.success) {
      console.log("Success");
      console.log("DATA 2:", data2);

      console.log("Content added!!");
      alert("Success! Content updated.");
    } else {
      console.error("Failed...");
      console.log("data2: ", data2);
      alert("Failed to update content.");
    }
    // } catch (error) {
    //   console.error("Failed: ", error.message);
    // }
  };

  const handleFileChange = (event) => {
    setSponsorImg(event.target.files[0]); // Get the first file
  };

  const handleSubmitSponsor = async (e) => {
    e.preventDefault();
    console.log("SDfSF");
    //try {
    let formData = new FormData();
    if (sponsorId === "" || sponsorId == null) {
      console.log("Sponsor ID is null.");
      alert("Sponsor ID is null.");
      return;
    }
    if (sponsorImg == null) {
      console.log("Sponsor Image is null.");
      alert("Sponsor Image is null.");
      return;
    }

    console.log("sponsorId: ", sponsorId);
    formData.append("sponsorID", sponsorId);
    formData.append("image", sponsorImg);

    //hard coded sorry
    formData.append("sponsorName", "Home");

    //console.log("here");
    const response = await fetch(URL + "updateSponsorsAPI.php", {
      method: "POST",
      body: formData,
      credentials: "include",
    });

    console.log("Response Status:", response.status);

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.text();
    console.log("Server Response: ", response.status, data);
    const data2 = JSON.parse(data);
    if (data2.success) {
      console.log("Success");
      console.log("DATA 2:", data2);
      alert("Success! Image updated.");
      console.log("Sponsor updated!!");
    } else {
      console.error("Failed...");
      console.log("data2: ", data2);
      alert("Failed to update image.");
    }
    // } catch (error) {
    //   console.error("Failed: ", error.message);
    // }
  };

  return (
    <div>
      <div className="topSpaceContainer"></div>
      <form onSubmit={handleSubmit}>
        <label htmlFor="contentId">Content Page:</label>
        <select
          id="contentId"
          defaultValue={contentId}
          onChange={(e) => setContentId(e.target.value)}
          required
        >
          <option value="">Select Content Page</option>
          <option value="1">Home Page</option>
        </select>
        <label htmlFor="content">Content Text:</label>
        <input
          type="text"
          id="content"
          value={content}
          onChange={(e) => setContent(e.target.value)}
          required
        />

        <button type="submit">Update Content</button>
      </form>

      <form onSubmit={handleSubmitSponsor}>
        <label htmlFor="sponsorId">Sponsor:</label>
        <select
          id="sponsorId"
          defaultValue={sponsorId}
          onChange={(e) => setSponsorId(e.target.value)}
          required
        >
          <option value="">Select Content Page</option>
          <option value="1">Home Page</option>
        </select>
        <label htmlFor="sponsor">Image:</label>
        <input type="file" onChange={handleFileChange} />

        <button type="submit">Update Image</button>
      </form>
    </div>
  );
};
export default AddContent;
