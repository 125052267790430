import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import { URL } from "../components/URL";
import "react-phone-number-input/style.css";

const BulkRegistration = () => {
  const [shooterName, setShooterName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNum, setPhoneNum] = useState("");
  const [affiliation, setAffiliation] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [DOB, setDOB] = useState("");
  const { tourneyId } = useParams();
  const [classes, setClasses] = useState([]);
  const [selectedClassId, setSelectedClassId] = useState("");
  const [selectedClassCost, setSelectedClassCost] = useState("");
  const [isPublic, setIsPublic] = useState(null);
  const [privateCode, setPrivateCode] = useState("");
  const [archers, setArchers] = useState([]);
  const [totalCost, setTotalCost] = useState(0);
  useEffect(() => {
    callCreateRegFormAPI();
  }, []);
  const handleAdd = () => {
    setArchers([
      ...archers,
      { name: "", classID: "", DOB: "", affiliation: "" },
    ]);
  };

  const handleChange = (e, index, field) => {
    const updatedArchers = [...archers];
    updatedArchers[index][field] = e.target.value;
    setArchers(updatedArchers);
  };

  const handleDelete = (index) => {
    const updatedArchers = [...archers];
    updatedArchers.splice(index, 1);
    setArchers(updatedArchers);
  };
  const handleClassChange = (event, index) => {
    const selectedId = parseInt(event.target.value);
    const updatedArchers = [...archers];
    updatedArchers[index].classID = selectedId;
    setArchers(updatedArchers);

    let total = 0;
    updatedArchers.forEach((archer) => {
      const selectedClass = classes.find(
        (classItem) => classItem.classId === parseInt(archer.classID)
      );
      if (selectedClass) {
        total += selectedClass.classCost;
      }
    });
    setTotalCost(total);
  };
  const callCreateRegFormAPI = async () => {
    // add if statements with else at the end
    try {
      let formData = new FormData();
      formData.append("tournamentID", tourneyId);
      console.log(formData);

      const response = await fetch(URL + "createRegFormAPI.php", {
        method: "POST",
        body: formData,
        credentials: "include",
      });

      console.log("Response Status:", response.status);

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.text();
      console.log("Server Response: ", response.status, data);
      const data2 = JSON.parse(data);
      if (data2.success) {
        console.log("successful");
        console.log("LOOK at this: ", data2);
        setClasses(data2.typeClasses);
        setIsPublic(data2.public);
        console.log("classseeeeeeeeeeeeee:", classes);
      } else {
        console.error("Form submission failed");
        console.log("data2: ", data2);
      }
    } catch (error) {
      console.error("Error during form submission: ", error.message);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    // add if statements with else at the end
    try {
      let formData = new FormData();
      // check the API names
      if (isPublic == 0) {
        formData.append("privateCode", privateCode);
      }
      formData.append("tourneyID", tourneyId);
      formData.append("email", email);
      formData.append("phoneNum", phoneNum);
      formData.append("street", street);
      formData.append("city", city);
      formData.append("state", state);
      formData.append("zipCode", zipCode);
      formData.append("isPublic", isPublic);
      if (isPublic == 0) {
        formData.append("code", privateCode);
      }
      for (let [index] of archers.entries()) {
        formData.append("info[]", JSON.stringify(archers[index]));
      }
      console.log(formData);

      const response = await fetch(URL + "bulkRegistrationAPI.php", {
        method: "POST",
        body: formData,
        credentials: "include",
      });

      console.log("Response Status:", response.status);

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.text();
      console.log("Server Response: ", response.status, data);
      const data2 = JSON.parse(data);
      console.log("archers: ", archers);
      if (data2.success) {
        console.log("Form submission successful");
        alert("Registration Submitted!!");
        setTimeout(() => {
          window.location.replace(URL + "tournament");
        }, 1000);
        console.log("data2: ", data2);
      } else {
        console.error("Form submission failed");
        console.log("data2: ", data2);
        alert("Form submission failed.");
      }
    } catch (error) {
      console.error("Error during form submission: ", error.message);
      alert("Error during form submission: ", error.message);
    }
  };

  return (
    <>
      <h1 className="tournaments-heading" style={{ textAlign: "center" }}>
        Bulk Registration:
      </h1>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "20px",
            backgroundColor: "#f0f0f0",
            padding: "20px",
            borderRadius: "10px",
          }}
        >
          {isPublic === 0 ? (
            <>
              <label htmlFor="privateCode" style={{ width: "400px" }}>
                Enter Private Code:
              </label>
              <input
                style={{ width: "400px" }}
                type="text"
                id="privateCode"
                value={privateCode}
                onChange={(e) => setPrivateCode(e.target.value)}
                required
              />
            </>
          ) : null}
          <p style={{ width: "400px" }}>Coach's Information:</p>
          <label htmlFor="email" style={{ width: "400px" }}>
            Email Address:
          </label>
          <input
            style={{ width: "400px" }}
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <label htmlFor="phoneNum" style={{ width: "400px" }}>
            Phone Number:
          </label>
          <PhoneInput
            style={{ width: "400px" }}
            country={"us"} // Set default country to United States
            value={phoneNum}
            onChange={(value) => setPhoneNum(value)}
            inputprops={{
              id: "phoneNum",
              required: true,
            }}
          />
          <label htmlFor="street" style={{ width: "400px" }}>
            Street:
          </label>
          <input
            style={{ width: "400px" }}
            type="text"
            id="street"
            value={street}
            onChange={(e) => setStreet(e.target.value)}
            required
          />
          <label style={{ width: "400px" }} htmlFor="city">
            City:
          </label>
          <input
            style={{ width: "400px" }}
            type="text"
            id="city"
            value={city}
            onChange={(e) => setCity(e.target.value)}
            required
          />
          <label htmlFor="state" style={{ width: "400px" }}>
            State:
          </label>
          <select
            style={{ width: "400px" }}
            id="state"
            value={state}
            onChange={(e) => setState(e.target.value)}
            required
          >
            <option value="AL">AL</option>
            <option value="AK">AK</option>
            <option value="AZ">AZ</option>
            <option value="AR">AR</option>
            <option value="CA">CA</option>
            <option value="CO">CO</option>
            <option value="CT">CT</option>
            <option value="DE">DE</option>
            <option value="FL">FL</option>
            <option value="GA">GA</option>
            <option value="HI">HI</option>
            <option value="ID">ID</option>
            <option value="IL">IL</option>
            <option value="IN">IN</option>
            <option value="IA">IA</option>
            <option value="KS">KS</option>
            <option value="KY">KY</option>
            <option value="LA">LA</option>
            <option value="ME">ME</option>
            <option value="MD">MD</option>
            <option value="MA">MA</option>
            <option value="MI">MI</option>
            <option value="MN">MN</option>
            <option value="MS">MS</option>
            <option value="MO">MO</option>
            <option value="MT">MT</option>
            <option value="NE">NE</option>
            <option value="NV">NV</option>
            <option value="NH">NH</option>
            <option value="NJ">NJ</option>
            <option value="NM">NM</option>
            <option value="NY">NY</option>
            <option value="NC">NC</option>
            <option value="ND">ND</option>
            <option value="OH">OH</option>
            <option value="OK">OK</option>
            <option value="OR">OR</option>
            <option value="PA">PA</option>
            <option value="RI">RI</option>
            <option value="SC">SC</option>
            <option value="SD">SD</option>
            <option value="TN">TN</option>
            <option value="TX">TX</option>
            <option value="UT">UT</option>
            <option value="VT">VT</option>
            <option value="VA">VA</option>
            <option value="WA">WA</option>
            <option value="WV">WV</option>
            <option value="WI">WI</option>
            <option value="WY">WY</option>
          </select>
          <label htmlFor="zipCode" style={{ width: "400px" }}>
            Zip Code:
          </label>
          <input
            style={{ width: "400px" }}
            type="text"
            id="zipCode"
            value={zipCode}
            onChange={(e) => setZipCode(e.target.value)}
            pattern="[0-9]{5}"
            title="Please enter a valid 5-digit zip code"
            required
          />
          <p style={{ width: "400px" }}>Archers List:</p>
          {archers.map((archer, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "20px",
              }}
            >
              <label htmlFor={`name${index}`} style={{ width: "400px" }}>
                Name:
              </label>
              <input
                style={{ width: "400px" }}
                type="text"
                id={`name${index}`}
                placeholder="Enter first and last name"
                value={archer.name}
                onChange={(e) => handleChange(e, index, "name")}
              />
              <label htmlFor={`classID${index}`} style={{ width: "400px" }}>
                Class:
              </label>
              <select
                style={{ width: "400px" }}
                id={`classID${index}`}
                value={archer.classID}
                onChange={(e) => handleClassChange(e, index)}
              >
                <option value="" style={{ width: "400px" }}>
                  Select a class
                </option>
                {classes.map((classItem) => (
                  <option key={classItem.classId} value={classItem.classId}>
                    {classItem.description}
                  </option>
                ))}
              </select>
              <p>
                Cost: $
                {archer.classID &&
                  classes.find((item) => item.classId === archer.classID) &&
                  (
                    classes.find((item) => item.classId === archer.classID)
                      .classCost / 100
                  ).toFixed(2)}
              </p>
              <label style={{ width: "400px" }} htmlFor={`DOB${index}`}>
                Date of Birth:
              </label>
              <input
                style={{ width: "400px" }}
                type="date"
                id={`DOB${index}`}
                value={archer.DOB}
                onChange={(e) => handleChange(e, index, "DOB")}
              />
              <label htmlFor={`affiliation${index}`} style={{ width: "400px" }}>
                Affiliation (optional):
              </label>
              <input
                style={{ width: "400px" }}
                type="text"
                id={`affiliation${index}`}
                placeholder="Enter affiliation"
                value={archer.affiliation}
                onChange={(e) => handleChange(e, index, "affiliation")}
              />

              <button onClick={() => handleDelete(index)}>Delete</button>
            </div>
          ))}
          <button onClick={handleAdd}>Add Archer</button>
          <p>Total Cost: ${(totalCost / 100).toFixed(2)}</p>
          <button onClick={handleSubmit}>Submit Registration</button>
        </div>
      </div>
    </>
  );
};

export default BulkRegistration;
