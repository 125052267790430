import React, { useState, useEffect } from "react";
import "./styles.css";
import { useParams } from "react-router-dom";
import { URL } from "../components/URL";

const AdminClassCost = () => {
  const { tourneyId } = useParams();
  const [classes, setClasses] = useState([]);
  const [classIds, setClassIds] = useState([]);
  const [classCosts, setClassCosts] = useState({});
  const [classCosts2, setClassCosts2] = useState({});

  useEffect(() => {
    callCreateRegFormAPI();
  }, []);

  const callCreateRegFormAPI = async () => {
    try {
      let formData = new FormData();
      formData.append("tournamentID", tourneyId);

      const response = await fetch(URL + "createRegFormAPI.php", {
        method: "POST",
        body: formData,
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.text();
      const data2 = JSON.parse(data);
      if (data2.success) {
        setClasses(data2.typeClasses);
        const ids = data2.typeClasses.map((classItem) => classItem.classId);
        setClassIds(ids);
        const costs = {};
        data2.typeClasses.forEach((classItem) => {
          costs[classItem.classId] = classItem.classCost;
        });
        setClassCosts(costs);
        setClassCosts2(costs);
        alert("Success. Class Costs Changed!!");
      } else {
        console.error("Form submission failed");
        console.log("data2: ", data2);
        alert("error. form submission failed.");
      }
    } catch (error) {
      console.error("Error during form submission: ", error.message);
      alert("Error during form submission: ", error.message);
    }
  };

  const handleClassCostChange = (classId, event) => {
    const value = parseInt(event.target.value);
    setClassCosts2((prevCosts) => {
      const updatedCosts = { ...prevCosts };
      updatedCosts[classId] = value;
      return updatedCosts;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let formData = new FormData();
      let class1 = [];
      for (let [index, classId] of classIds.entries()) {
        let classOb = {};
        classOb["classCost"] = classCosts2[classId];
        classOb["classID"] = classId;
        formData.append("class[]", JSON.stringify(classOb));
      }

      formData.append("tourneyID", tourneyId);

      const response = await fetch(URL + "addCostsAPI.php", {
        method: "POST",
        body: formData,
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.text();
      console.log("Server Response: ", response.status, data);
      const data2 = JSON.parse(data);
      if (data2.success) {
        alert("Class costs changed successfully!!");
        console.log("successful");
        console.log("LOOK at this: ", data2);
      } else {
        console.error("Form submission failed");
        alert("Class costs change failed.");
        console.log("data2: ", data2);
      }
    } catch (error) {
      console.error("Error during form submission: ", error.message);
    }
  };

  return (
    <div>
      <div className="topSpaceContainer"></div>
      <form onSubmit={handleSubmit}>
        <p>Add Costs in Pennies</p>
        <p>Classes:</p>
        <ul style={{ listStyleType: "none" }}>
          {classes.map((classItem) => (
            <li key={classItem.classId}>
              {classItem.description}. Current Cost:
              {classCosts[classItem.classId]
                ? `$${(classCosts[classItem.classId] / 100).toFixed(2)}`
                : ""}
              <input
                type="text"
                value={classCosts2[classItem.classId] || ""}
                onChange={(e) => handleClassCostChange(classItem.classId, e)}
              />
            </li>
          ))}
        </ul>
        <button type="submit">Submit Cost Changes</button>
      </form>
    </div>
  );
};

export default AdminClassCost;
